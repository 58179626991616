<template>
    <v-flex fill-height>
        <v-layout justify-space-between pa-2 row>
            <v-flex v-t="'sa.support.actions.affect-theme-to-users.title'" headline shrink />
        </v-layout>
		<v-layout fill-height pa-4>
            <v-flex>
                <v-layout column justify-center>
                    <v-flex my-3 shrink>
                        <v-layout column>
                            <v-flex v-t="'sa.support.actions.affect-theme-to-users.accounting-firm'" class="title" mb-3 />
                            <v-autocomplete
                                v-model="selectedAccountingFirmId"
                                color="primary"
                                :items="accountingFirms"
                                :label="$t('accounting_firm')"
                                :loading="loadingAccountingFirmsList"
                                @change="getThemes(selectedAccountingFirmId)"
                            />
                            <v-flex v-t="'sa.support.actions.affect-theme-to-users.theme'" class="title" mb-3 />
                            <v-autocomplete
                                v-model="selectedThemeId"
                                :disabled="!selectedAccountingFirmId"
                                color="primary"
                                :items="themes"
                                :label="$t('theme.label')"
                                :loading="loadingThemesList"
                            />
                            </v-layout>
                            <v-flex v-t="'sa.support.actions.affect-theme-to-users.user-type'" class="title" mb-3 />
                            <w-select
                                v-model="userType"
                                :items="userTypes"
                                item-text="type d'utilisateur"
                                item-value="id"
                                :label="$t('sa.support.actions.affect-theme-to-users.user-type')"
                                :loading="userTypesLoading"
                                required
                            />
                            <v-flex v-t="'sa.support.actions.affect-theme-to-users.user-role'" class="title" mb-3 />
                            <w-select
                                v-model="userRole"
                                :items="userRoles"
                                item-text="rôle des utilisateurs"
                                item-value="id"
                                :label="$t('sa.support.actions.affect-theme-to-users.user-role')"
                                :loading="userRolesLoading"
                                required
                            />
                    </v-flex>
                    <v-flex my-3 shrink>
                        <WButton :disabled="!selectedThemeId || !selectedAccountingFirmId" @click="execute">{{ $t('actions.launch') }}</WButton>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
    </v-flex>
</template>

<script>
import SuperAdminModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminModuleGuard'
import CatalogThemeService from '@/services/CatalogTheme/CatalogThemeService'

export default {
    name: 'AffectThemeToUsers',
    mixins: [SuperAdminModuleGuard],
    data: function () {
		return {
            accountingFirms: new Array(),
            selectedAccountingFirmId: null,
            loadingAccountingFirmsList: false,
            themes: new Array(),
            selectedThemeId: null,
            loadingThemesList: false,
            userTypes: new Array('all', 'accountant', 'client'),
            userType: 'all',
            userTypesLoading: false,
            userRoles: new Array('all', 'admin', 'collaborator', 'guest'),
            userRole: 'all',
            userRolesLoading: false,
        }
    },
    created: function () {
		this.getAccountingFirms()
	},
    methods: {
        getAccountingFirms: function () {
			this.loadingAccountingFirmsList = true
			this.service
				.getAccountingFirms()
				.then(accountingFirms => {
					return accountingFirms.map(accountingFirm => {
						return {
							text: accountingFirm.name,
							value: accountingFirm.id,
						}
					})
				})
				.then(accountingFirms => {
					this.accountingFirms = accountingFirms
				})
				.finally(() => {
					this.loadingAccountingFirmsList = false
				})
		},
        getThemes: function () {
            if(this.selectedAccountingFirmId) {
                this.loadingThemesList = true
                this.service.getAccountingFirm(this.selectedAccountingFirmId)
                    .then(accountingFirm => {
                        return CatalogThemeService.getCatalogThemes(accountingFirm.catalog_tree_structure_id, [])
                    })
                    .then(themes => {
                        return themes.map(theme => {
                            return {
                                text: theme.title,
                                value: theme.id
                            }
                        })
                    })
                    .then(themes => {
                        this.themes = themes
                    })
                    .finally(() => {
                        this.loadingThemesList = false
                    })

            }
		},
        execute: function () {
            return this.service.executeCommand({
                    command: "users:theme:accounting-firm:update",
                    params: {
                        'accounting-firm-id': this.selectedAccountingFirmId,
                        'catalog-theme-id': this.selectedThemeId,
                        '--users-type': this.userType,
                        '--users-role': this.userRole,
                        '--force': true,
                    }
            }).then(() => {
                this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('sa.support.success'))
            }).catch(error => {
                this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, error)
            })
        }
    }
}
</script>
import { Config } from '@/services/ConfigService'
import { Configurator } from '@/plugins/axios/Configurator'

const axios = Configurator.defaultConfiguration(Config.VUE_APP_ROOT_API + '/api/')

const getCatalogThemesOfACatalogTreeStructure = (catalogTreeStructureId, data) => {
	const url = `catalog-tree-structures/${catalogTreeStructureId}/catalog-themes`
	return axios.get(url, data)
}

export default {
	getCatalogThemesOfACatalogTreeStructure: getCatalogThemesOfACatalogTreeStructure
}

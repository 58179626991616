// ============================================================================
// CatalogThemeService
// -------------------
// CatalogTheme module related services
// ============================================================================

// -------
// Imports
// -------
import API from '@/apis/CatalogThemeApi'

// -------
// Exports
// -------
export default {
	getCatalogThemes: function (catalogTreeStructureId, data) {
		return API.getCatalogThemesOfACatalogTreeStructure(catalogTreeStructureId, data).then(res => res.data.data)
	}
}
